import { DEFAULT_CACHE_KEY, generateCacheTTL } from '../../utils/functions/cache';
import {
  UPDATE_CLIENTS_CURRENT_CACHE_KEY,
  GET_CLIENTS_API_REQUEST,
  GET_CLIENTS_API_SUCCESS,
  GET_CLIENTS_API_ERROR,
  GET_NEXT_CLIENTS_SUCCESS,
  GET_SINGLE_CLIENT_API_REQUEST,
  GET_SINGLE_CLIENT_API_SUCCESS,
  GET_SINGLE_CLIENT_API_ERROR,
  UPDATE_SINGLE_CLIENT_API_REQUEST,
  UPDATE_SINGLE_CLIENT_API_SUCCESS,
  UPDATE_SINGLE_CLIENT_API_ERROR
} from '../../constants/clientActionNames';
import { RESET_INFINITE_SCROLL_SUCCESS } from '../../constants/miscActionNames';
import { SELECT_CLIENTS_SUCCESS } from '../../constants/authActionNames';
import payloadData from './payloadData';
import {
  uuidv4,
  dateToString,
  stringQuoteToDate,
  isoStringToDate,
  getAllKeys,
  getKeys,
  uniq,
  addArrayOnce,
} from '../../utils/functions';
import { createPayload as createProfilePayload } from '../profile';
import { persistKey, statusOk } from '../../utils/variables';

// .slice(0, loadClientsCount)
let searchKeys = [];

export const createPayload = (payloadArray, lobTypes, cacheKey) => {
  if (!Array.isArray(payloadArray)) return null;
  searchKeys = [];
  let newClients = payloadArray?.map((object, index) => {
    const lobObj = lobTypes?.find(lobObject => {
      const lobIndex = object?.lob?.toLowerCase() === lobObject.lob;
      // // console.log('index: ', index2, lobObject.lob);

      return lobIndex;
    });
    const position = index;
    const convertedDate = stringQuoteToDate(object?.createTime);
    // // console.log('convertedDate: ', convertedDate);

    // console.log("icon: " + JSON.stringify(lobObj));
    if (!object?.firstName && !object?.lastName && !object?.masterId) return null;
    const client = {
      ...createProfilePayload(object),
      index: position,
      id: object?._id, // object?._id, // ? `${object._id}_${position}` : uuidv4(), // `${object?._id}_${uiid}`,
      clientNumber: object?.masterId, // object?._id,
      slug: object?.masterId, // object?._id,
      icon: lobObj?.materialIcon,
      cacheId: `${object?._id}_${cacheKey}`,
      cacheKey,
      // dob: object?.dob,
      dob: dateToString(isoStringToDate(object?.dob)),
      createTime: object?.createTime, // dateToString(isoStringToDate(object?.quoteData?.createTime)),
      expiredTime: object?.expiredTime, // dateToString(isoStringToDate(object?.quoteData?.expiredTime)),
      firstName: object?.firstName,
      lastName: object?.lastName,
      // gender:
      // object?.gender == 'M' ? 'Male' : object?.gender == 'F' ? 'Female' : 'Others',
      // ...searchWords,
    };

    // const searchWords = generateSearchWords(object);
    // const newKeys = getKeys(searchWords);
    // // console.log('newKeys: ', newKeys);
    // searchKeys = [...searchKeys, ...newKeys];
    // searchKeys = uniq(searchKeys);
    // // console.log('searchWords: ', searchWords);

    // const reduceObj = reduceKeys(obj);
    const newKeys = getKeys(client);
    searchKeys = [...searchKeys, ...newKeys];
    searchKeys = uniq(searchKeys);
    // // console.log('searchKeys: ', searchKeys);

    return client;
  });
  newClients = newClients?.filter(x => x !== null);
  /*
  newClients.sort((a, b) => {
    return b.slug > a.slug ? 1 : -1;
  });
  */

  return newClients;
};

export const initialState = {
  isPending: false,
  isError: false,
  isSuccess: false,
  response: null,
  error: null,
  allRows: [],
  count: 0,
  nextItemCount: 0,
  nextScrollCount: 0,
  nextStartIndex: 0,
  searchKeys,
  [persistKey]: null,
  [DEFAULT_CACHE_KEY]: null,
  currentCacheKey: '',
  client: null,
};
// createPayload(payloadData, 0)

export const getPayloadResponse = () => {
  // // console.log('params: ', params);
  return { payload: [{ clients: payloadData, count: 100000 }] };
};

function clientsReducer(state = initialState, action) {
  switch (action.type) {
    case GET_CLIENTS_API_REQUEST:
      return {
        ...state,
        isPending: true,
        isError: false,
        isSuccess: false,
      };
    case GET_CLIENTS_API_SUCCESS: {
      const lobTypes = action?.lobTypes;
      const body = action?.body;
      // console.log('action: ', action);
      const cacheKey = action?.cacheKey;
      const rowPayload = createPayload(
        action?.payload?.payload?.[0]?.result?.data || [],
        lobTypes,
        cacheKey,
      );
      const newRows = rowPayload;
      // console.log('newRows: ', newRows);
      // console.log('body: ', body);
      const newTotal = action?.payload?.payload?.[0]?.result?.total || 0;
      const response = action?.payload;
      const isError = response?.responseType !== statusOk;
      // console.log('insurances: ', insurances);
      // const insuranceCollections = collections;

      const nextStartIndex = body?.nextStartIndex;
      const nextScrollCount = body?.nextScrollCount;
      const nextItemCount = body?.nextItemCount;

      const count = typeof state?.count === 'object' ? { ...state?.count } : {};
      count[cacheKey] = newTotal;

      return {
        ...state,
        total: newTotal,
        isPending: false,
        isError,
        isSuccess: true,
        response,
        // rows,
        count,
        searchKeys,
        allRows: newRows,
        // [persistKey]: nowTime,
        currentCacheKey: cacheKey,
        [DEFAULT_CACHE_KEY]: {
          ...(state[DEFAULT_CACHE_KEY] || {}),
          [cacheKey]: {
            time: generateCacheTTL(),
            payload: response,
          },
        },
        nextItemCount,
        nextScrollCount,
        nextStartIndex,
      };
    }
    case GET_CLIENTS_API_ERROR:
      return {
        ...state,
        isPending: false,
        isError: true,
        isSuccess: false,
        error: action?.payload,
      };

    case GET_SINGLE_CLIENT_API_REQUEST:
      return {
        ...state,
        isSingleClientPending: true,
        isSingleClientError: false,
        isSingleClientSuccess: false,
      };
    case GET_SINGLE_CLIENT_API_SUCCESS: {
      const singleClient = createPayload([action?.payload?.payload])[0];
      return {
        ...state,
        isSingleClientPending: false,
        isSingleClientError: false,
        isSingleClientSuccess: true,
        client: singleClient,
      };
    }
    case GET_SINGLE_CLIENT_API_ERROR:
      return {
        ...state,
        isSingleClientPending: false,
        isSingleClientError: true,
        isSingleClientSuccess: false,
      };

    case UPDATE_CLIENTS_CURRENT_CACHE_KEY:
      return {
        ...state,
        currentCacheKey: action?.payload,
      };

    case GET_NEXT_CLIENTS_SUCCESS: {
      const nextStartIndex = action?.payload?.nextStartIndex;
      const nextScrollCount = action?.payload?.nextScrollCount;
      const nextItemCount = action?.payload?.nextItemCount;

      return {
        ...state,

        nextItemCount,
        nextScrollCount,
        nextStartIndex,
      };
    }

    case RESET_INFINITE_SCROLL_SUCCESS: {
      return {
        ...state,

        nextItemCount: 0,
        nextScrollCount: 0,
        nextStartIndex: 0,
      };
    }

    case SELECT_CLIENTS_SUCCESS: {
      return {
        ...state,
        [persistKey]: null,
      };
    }

    case UPDATE_SINGLE_CLIENT_API_REQUEST: {
      return {
        ...state,
        isSingleClientPending: true,
        isSingleClientError: false,
        isSingleClientSuccess: false,
      };
    }
    case UPDATE_SINGLE_CLIENT_API_SUCCESS: {
      return {
        ...state,
        isSingleClientPending: false,
        isSingleClientError: false,
        isSingleClientSuccess: true,
        client: {
          ...state.client,
          ...action.payload, // incase any key is not present under createProfileFUnc
          ...createProfilePayload(action.payload),
        },
      };
    }
    case UPDATE_SINGLE_CLIENT_API_ERROR: {
      return {
        ...state,
        isSingleClientPending: false,
        isSingleClientError: true,
        isSingleClientSuccess: false,
      };
    }

    default:
      return state;
  }
}

export default clientsReducer;
