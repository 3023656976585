import React from 'react';
import { makeStyles } from '@mui/styles';
import { useTheme } from '@mui/material/styles';
import {
  Grid,
  Button,
  // Grid,
  Typography,
  DialogActions as MuiDialogActions,
  DialogContent as MuiDialogContent,
} from '@mui/material';
import { IosShare } from '../../icons';
import { darkModeLogo, lightModeLogo } from '../../../utils/variables/images';
import { themeTypes } from '../../../themes';
import styles from './style';
import { isSafari, isFirefox } from '../../../utils/functions';

const useStyles = makeStyles(styles);

const DownloadContent = ({ webInstallPrompt, handleWebInstallAccepted }) => {
  const classes = useStyles();
  const theme = useTheme();
  const isiOS13 = /OS 13/.test(window.navigator.userAgent);
  const safari = isSafari();
  const firefox = isFirefox();
  return (
    <>
      <MuiDialogContent>
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <img
              alt="companyLogo"
              className={classes.image}
              src={theme.palette.mode === themeTypes.dark ? darkModeLogo : lightModeLogo}
            />
          </Grid>
          <Grid item xs={12}>
            <Typography variant="body1" color="text.primary" align="justify">
              We've been hard at work building our online services and have been
              envisioning this moment for some time. You are being redirected to our new
              user friendly, self-service platform, with secure access to your entire
              insurance and investment portfolio, all in one place.
            </Typography>
          </Grid>
          {safari && (
            <Grid item xs={12}>
              <Typography align="left" variant="h6" sx={{ color: 'text.primary' }}>
                Tap
                <IosShare
                  modern={isiOS13}
                  className={classes.font}
                  height={60}
                  width={60}
                />
                icon, then click &quot;Add to Home Screen&quot;
              </Typography>

              <Typography
                align="left"
                variant="body1"
                sx={{ color: 'text.primary', mt: 2 }}
              >
                If the 'Add to Home Screen' option is not there, then you may have already
                downloaded the app.
              </Typography>
            </Grid>
          )}
          {firefox && (
            <Grid item xs={12}>
              <Typography align="left" variant="h6" sx={{ color: 'text.primary' }}>
                The FireFox browser is not capable of downloading the app to your phone.
                Please use another browser.
              </Typography>
            </Grid>
          )}
          {!safari && !firefox && (
            <Grid item xs={12}>
              {webInstallPrompt ? (
                <Button
                  size="large"
                  onClick={handleWebInstallAccepted}
                  className={classes.primaryButton}
                >
                  Click to install and enjoy this new experience.
                </Button>
              ) : (
                <Typography align="left" variant="h6" sx={{ color: 'text.primary' }}>
                  Please note that, you have already downloaded the app on this device.
                </Typography>
              )}
            </Grid>
          )}
        </Grid>
      </MuiDialogContent>
    </>
  );
};

export default DownloadContent;
