export const UPDATE_CLIENTS_CURRENT_CACHE_KEY = 'UPDATE_CLIENTS_CURRENT_CACHE_KEY';
export const GET_CLIENTS_API_REQUEST = 'GET_CLIENTS_API_REQUEST';
export const GET_CLIENTS_API_SUCCESS = 'GET_CLIENTS_API_SUCCESS';
export const GET_CLIENTS_API_ERROR = 'GET_CLIENTS_API_ERROR';
export const GET_NEXT_CLIENTS_SUCCESS = 'GET_NEXT_CLIENTS_SUCCESS';

export const GET_SINGLE_CLIENT_API_REQUEST = 'GET_SINGLE_CLIENT_API_REQUEST';
export const GET_SINGLE_CLIENT_API_SUCCESS = 'GET_SINGLE_CLIENT_API_SUCCESS';
export const GET_SINGLE_CLIENT_API_ERROR = 'GET_SINGLE_CLIENT_API_ERROR';

export const UPDATE_SINGLE_CLIENT_API_REQUEST = 'UPDATE_SINGLE_CLIENT_API_REQUEST';
export const UPDATE_SINGLE_CLIENT_API_SUCCESS = 'UPDATE_SINGLE_CLIENT_API_SUCCESS';
export const UPDATE_SINGLE_CLIENT_API_ERROR = 'UPDATE_SINGLE_CLIENT_API_ERROR';